<template lang="html">
  <div>
    <div class="desktopPlanner" v-if="!mobile">
      <!-- <button
        type="button"
        name="button"
        class="btn btn-danger"
        @click="addPlanner"
      >
        OVO DUGME NE TREBA DA SE VIDI
      </button> -->

      <h1>Planner</h1>
      <div class="headerGodina">
        <div class="leva">
          <button type="button" class="left" @click="lastYear" disabled>
            prethodna
          </button>
        </div>
        <div class="mesec">
          <h2>Godina: {{ godina }}.</h2>
        </div>
        <div class="desna">
          <button type="button" class="right" @click="nextYear" disabled>
            naredna
          </button>
        </div>
      </div>
      <!-- v-if="user.name=='admin'" -->

      <div class="headerKalendar">
        <div class="leva">
          <button type="button" class="left" @click="lastMonth">
            prethodni
          </button>
        </div>
        <div class="mesec">
          <h2 v-if="lockedPlanner" style="text-align:center;">(ZAKLJUCAN)</h2>
          <h2>Mesec: {{ mesec }}</h2>
        </div>
        <div class="desna">
          <button type="button" class="right" @click="nextMonth">
            naredni
          </button>
        </div>
        <p class="radniDan">PONEDELJAK</p>
        <p class="radniDan">UTORAK</p>
        <p class="radniDan">SREDA</p>
        <p class="radniDan">ČETVRTAK</p>
        <p class="radniDan">PETAK</p>
        <p class="vikend">SUBOTA</p>
        <p class="vikend">NEDELJA</p>
      </div>

      <div class="kalendar1">
        <div class="dan" v-for="(dan, i) in dani1" v-bind:key="dan.dan">
          <button
            type="button"
            class="btn btn-success"
            v-if="dan.dan != 0"
            @click="selectTrenutni(i)"
            style="font-size: x-small;"
          >
            {{ dan.dan }}.
          </button>
          <div class="" v-for="sastanak in dan.sastanci" v-bind:key="sastanak">
            <p style="font-size: small;">
              <b>{{ sastanak.startTime }}-{{ sastanak.endTime }}</b
              ><br />{{ sastanak.name }}
            </p>
          </div>

          <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-if="dan.dan!=0" v-model="dan.komentar"></textarea> -->
        </div>
      </div>
      <div class="kalendar1">
        <div class="dan" v-for="(dan, i) in dani2" v-bind:key="dan.dan">
          <button
            type="button"
            class="btn btn-success"
            v-if="dan.dan != 0"
            @click="selectTrenutni(i + 7)"
            style="font-size: x-small;"
          >
            {{ dan.dan }}.
          </button>
          <div class="" v-for="sastanak in dan.sastanci" v-bind:key="sastanak">
            <p style="font-size: small;">
              <b>{{ sastanak.startTime }}-{{ sastanak.endTime }}</b
              ><br />{{ sastanak.name }}
            </p>
          </div>
          <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-if="dan.dan!=0" v-model="dan.komentar"></textarea> -->
        </div>
      </div>
      <div class="kalendar1">
        <div class="dan" v-for="(dan, i) in dani3" v-bind:key="dan.dan">
          <button
            type="button"
            class="btn btn-success"
            v-if="dan.dan != 0"
            @click="selectTrenutni(i + 14)"
            style="font-size: x-small;"
          >
            {{ dan.dan }}.
          </button>
          <div class="" v-for="sastanak in dan.sastanci" v-bind:key="sastanak">
            <p style="font-size: small;">
              <b>{{ sastanak.startTime }}-{{ sastanak.endTime }}</b
              ><br />{{ sastanak.name }}
            </p>
          </div>
          <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-if="dan.dan!=0" v-model="dan.komentar"></textarea> -->
        </div>
      </div>

      <div class="kalendar1">
        <div class="dan" v-for="(dan, i) in dani4" v-bind:key="dan.dan">
          <button
            type="button"
            class="btn btn-success"
            v-if="dan.dan != 0"
            @click="selectTrenutni(i + 21)"
            style="font-size: x-small;"
          >
            {{ dan.dan }}.
          </button>
          <div class="" v-for="sastanak in dan.sastanci" v-bind:key="sastanak">
            <p style="font-size: small;">
              <b>{{ sastanak.startTime }}-{{ sastanak.endTime }}</b
              ><br />{{ sastanak.name }}
            </p>
          </div>
          <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-if="dan.dan!=0" v-model="dan.komentar"></textarea> -->
        </div>
      </div>

      <div class="kalendar2">
        <div class="dan" v-for="(dan, i) in dani5" v-bind:key="dan.dan">
          <button
            type="button"
            class="btn btn-success"
            v-if="dan.dan != 0"
            @click="selectTrenutni(i + 28)"
            style="font-size: x-small;"
          >
            {{ dan.dan }}.
          </button>
          <div class="" v-for="sastanak in dan.sastanci" v-bind:key="sastanak">
            <p style="font-size: small;">
              <b>{{ sastanak.startTime }}-{{ sastanak.endTime }}</b
              ><br />{{ sastanak.name }}
            </p>
          </div>
          <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-if="dan.dan!=0" v-model="dan.komentar"></textarea> -->
        </div>
      </div>

      <div class="buttonsDiv">
        <button
          class="btn btn-success bg-gradient border-dark my-2 me-1"
          type="button"
          name="button"
          @click="ucitaj"
        >
          Učitaj
        </button>
        <button
          v-if="!lockedPlanner"
          class="btn btn-danger bg-gradient border-dark my-2 me-1"
          type="button"
          name="button"
          @click="lockPlannerthis"
        >
          Zaključaj
        </button>
        <button
          v-if="lockedPlanner"
          class="btn btn-primary bg-gradient border-dark my-2"
          type="button"
          name="button"
          @click="unlockPlannerthis"
        >
          Otključaj
        </button>
      </div>
      <div class="validation">
        {{ validation2 }}
      </div>

      <div class="trenutniDan bg-success bg-gradient mb-5">
        <h3 style="text-align:center;">
          {{ trenutniDan.dan }}.{{ trenutniDan.mesec }}
        </h3>

        <label class="me-1" for="exampleFormControlTextarea2"
          ><b>Sastanci</b></label
        >

        <div
          class="my-2"
          v-for="(sas, i) in trenutniDan.sastanci"
          v-bind:key="i"
        >
          <input
            class="form-control"
            type="text"
            v-model="trenutniDan.sastanci[i].name"
            spellcheck="false"
          />
          <label class="mt-2 me-1">Vreme:</label>
          <input
            class="mt-2"
            type="text"
            spellcheck="false"
            maxlength="5"
            size="5"
            v-model="trenutniDan.sastanci[i].startTime"
          />
          <input
            class="mt-2"
            type="text"
            spellcheck="false"
            maxlength="5"
            size="5"
            v-model="trenutniDan.sastanci[i].endTime"
          />
          <button
            class="left btn-warning ms-2 mt-2"
            type="button"
            name="button"
            @click="ukloniSastanak(i)"
          >
            ukloni
          </button>
        </div>

        <button
          class="left btn-warning"
          type="button"
          name="button"
          @click="increaseSastanak"
        >
          Dodaj sastanak
        </button>

        <!-- <textarea class="form-control" id="exampleFormControlTextarea2" rows="5" v-model="trenutniDan.sastanci" ></textarea> -->
        <h3 style="text-align:center;">Taskovi</h3>
        <div class="taskovi mb-2">
          <div class="px-1">
            <label
              class="me-1"
              for="exampleFormControlTextarea1"
              style="text-align:center;"
              ><b>Nina</b></label
            >
            <div
              class="form-check"
              v-for="(task, i) in trenutniDan.komentar?.nina"
              v-bind:key="i"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="trenutniDan.komentar.nina[i].checked"
              />

              <textarea
                class="form-control mb-1"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="trenutniDan.komentar.nina[i].name"
                spellcheck="false"
              ></textarea>
              <!-- <input class="form-control" type="text" name=""  v-model="trenutniDan.komentar.nina[i].name"> -->
              <button
                class="left btn-warning my-1 float-end"
                type="button"
                name="button"
                @click="ukloniTask(i, 1)"
              >
                ukloni
              </button>
            </div>
            <button
              class="left btn-warning my-1"
              type="button"
              name="button"
              @click="increaseTask(1)"
            >
              Dodaj task
            </button>
          </div>

          <div class="px-1">
            <label
              class="me-1"
              for="exampleFormControlTextarea1"
              style="text-align:center;"
              ><b>Nataša</b></label
            >

            <div
              class="form-check"
              v-for="(task, i) in trenutniDan.komentar?.natasa"
              v-bind:key="i"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                v-model="trenutniDan.komentar.natasa[i].checked"
              />
              <textarea
                class="form-control mb-1"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="trenutniDan.komentar.natasa[i].name"
                spellcheck="false"
              ></textarea>

              <button
                class="left btn-warning my-1 float-end"
                type="button"
                name="button"
                @click="ukloniTask(i, 2)"
              >
                ukloni
              </button>
            </div>
            <button
              class="left btn-warning my-1"
              type="button"
              name="button"
              @click="increaseTask(2)"
            >
              Dodaj task
            </button>
          </div>

          <div class="px-1">
            <label
              class="me-1"
              for="exampleFormControlTextarea1"
              style="text-align:center;"
              ><b>Podrška </b></label
            >

            <div
              class="form-check"
              v-for="(task, i) in trenutniDan.komentar?.zorica"
              v-bind:key="i"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                v-model="trenutniDan.komentar.zorica[i].checked"
              />
              <textarea
                class="form-control mb-1"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="trenutniDan.komentar.zorica[i].name"
                spellcheck="false"
              ></textarea>

              <button
                class="left btn-warning my-1 float-end"
                type="button"
                name="button"
                @click="ukloniTask(i, 3)"
              >
                ukloni
              </button>
            </div>
            <button
              class="left btn-warning my-1"
              type="button"
              name="button"
              @click="increaseTask(3)"
            >
              Dodaj task
            </button>
          </div>

          <div class="px-1">
            <label
              class="me-1"
              for="exampleFormControlTextarea1"
              style="text-align:center;"
              ><b>Anja</b></label
            >

            <div
              class="form-check"
              v-for="(task, i) in trenutniDan.komentar?.anja"
              v-bind:key="i"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                v-model="trenutniDan.komentar.anja[i].checked"
              />
              <textarea
                class="form-control mb-1"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="trenutniDan.komentar.anja[i].name"
                spellcheck="false"
              ></textarea>

              <button
                class="left btn-warning my-1 float-end"
                type="button"
                name="button"
                @click="ukloniTask(i, 4)"
              >
                ukloni
              </button>
            </div>
            <button
              class="left btn-warning my-1"
              type="button"
              name="button"
              @click="increaseTask(4)"
            >
              Dodaj task
            </button>
          </div>
        </div>

        <div class="validation">
          {{ validation }}
        </div>

        <div class="submitDiv">
          <button
            class="btn btn-warning bg-gradient w-100 fw-bold mt-3"
            @click="submit"
          >
            Sačuvaj Izmene
          </button>
        </div>
      </div>

      <!--
  <div class="kalendar">
    <div class="dan" v-for="(dan,i) in dani" v-bind:key="dan.dan">
      <button type="button" class="btn btn-success" v-if="dan.dan!=0" @click="selectTrenutni(i)" style="font-size: x-small;">{{dan.dan}}.</button>
      <p style="font-size: small;">{{dan.komentar}}</p>

    </div>
  </div> -->
    </div>

    <!-- END OF DESKTOP -->

    <div class="mobilePlanner" v-if="mobile">
      <div class="headerGodina">
        <div class="leva">
          <button type="button" class="left" @click="lastYear" disabled>
            prethodna
          </button>
        </div>
        <div class="mesec">
          <h2>Godina: {{ godina }}.</h2>
        </div>
        <div class="desna">
          <button type="button" class="right" @click="nextYear" disabled>
            naredna
          </button>
        </div>
      </div>
      <div class="headerKalendar">
        <div class="leva">
          <button type="button" class="left" @click="lastMonth">
            prethodni
          </button>
        </div>
        <div class="mesec">
          <h2 v-if="lockedPlanner" style="text-align:center;">(ZAKLJUCAN)</h2>
          <h2>Mesec: {{ mesec }}</h2>
        </div>
        <div class="desna">
          <button type="button" class="right" @click="nextMonth">
            naredni
          </button>
        </div>
        <p class="radniDan">PON</p>
        <p class="radniDan">UTO</p>
        <p class="radniDan">SRE</p>
        <p class="radniDan">ČET</p>
        <p class="radniDan">PET</p>
        <p class="vikend">SUB</p>
        <p class="vikend">NED</p>
      </div>

      <div class="kalendar1">
        <div
          class="danMobile"
          v-for="(dan, i) in dani1"
          v-bind:key="dan.dan"
          @click="selectTrenutni(i)"
        >
          <p v-if="dan.dan != 0">{{ dan.dan }}</p>
        </div>
      </div>

      <div class="kalendar1">
        <div
          class="danMobile"
          v-for="(dan, i) in dani2"
          v-bind:key="dan.dan"
          @click="selectTrenutni(i + 7)"
        >
          <p v-if="dan.dan != 0">{{ dan.dan }}</p>
        </div>
      </div>

      <div class="kalendar1">
        <div
          class="danMobile"
          v-for="(dan, i) in dani3"
          v-bind:key="dan.dan"
          @click="selectTrenutni(i + 14)"
        >
          <p v-if="dan.dan != 0">{{ dan.dan }}</p>
        </div>
      </div>

      <div class="kalendar1">
        <div
          class="danMobile"
          v-for="(dan, i) in dani4"
          v-bind:key="dan.dan"
          @click="selectTrenutni(i + 21)"
        >
          <p v-if="dan.dan != 0">{{ dan.dan }}</p>
        </div>
      </div>

      <div class="kalendar2">
        <div
          class="danMobile"
          v-for="(dan, i) in dani5"
          v-bind:key="dan.dan"
          @click="selectTrenutni(i + 28)"
        >
          <p v-if="dan.dan != 0">{{ dan.dan }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <button
            class="btn btn-success bg-gradient border-dark my-2 me-1 w-100"
            type="button"
            name="button"
            @click="ucitaj"
          >
            Učitaj
          </button>
        </div>
        <div v-if="!lockedPlanner" class="col-6">
          <button
            class="btn btn-danger bg-gradient border-dark my-2 me-1 w-100"
            type="button"
            name="button"
            @click="lockPlannerthis"
          >
            Zaključaj
          </button>
        </div>
        <div v-if="lockedPlanner" class="col-6">
          <button
            class="btn btn-primary bg-gradient border-dark my-2 w-100"
            type="button"
            name="button"
            @click="unlockPlannerthis"
          >
            Otključaj
          </button>
        </div>
      </div>
      <div class="validation">
        {{ validation2 }}
      </div>
      <div class="trenutniDan bg-success">
        <h1 class="fw-bold" style="text-align:center;">
          {{ trenutniDan.dan }}. {{ trenutniDan.mesec }}
        </h1>
        <div class="text-center">
          <label class="me-2 h2" for="exampleFormControlTextarea2"
            ><b>Sastanci</b></label
          >
        </div>
        <div class="" v-for="(sas, i) in trenutniDan?.sastanci" v-bind:key="i">
          <input
            class="form-control"
            type="text"
            v-model="trenutniDan.sastanci[i].name"
            spellcheck="false"
          />
          <label class="me-1 my-2">Vreme:</label>
          <input
            class=""
            type="text"
            spellcheck="false"
            maxlength="5"
            size="5"
            v-model="trenutniDan.sastanci[i].startTime"
          />
          <input
            class=""
            type="text"
            spellcheck="false"
            maxlength="5"
            size="5"
            v-model="trenutniDan.sastanci[i].endTime"
          />
          <button
            class="left btn-warning ms-2"
            type="button"
            name="button"
            @click="ukloniSastanak(i)"
          >
            ukloni
          </button>
        </div>

        <button
          class="left btn btn-warning w-100 mb-2 mt-1"
          type="button"
          name="button"
          @click="increaseSastanak"
        >
          Dodaj sastanak
        </button>

        <!-- <textarea class="form-control" id="exampleFormControlTextarea2" rows="5" v-model="trenutniDan.sastanci" ></textarea> -->
        <h1 class="mt-2" style="text-align:center;">Taskovi</h1>
        <div class="taskoviMobile">
          <div class="">
            <div class="text-center">
              <label
                class="me-1 my-2 h3"
                for="exampleFormControlTextarea1"
                style="text-align:center;"
                ><b>Nina</b></label
              >
            </div>
            <div
              class="form-check"
              v-for="(task, i) in trenutniDan.komentar?.nina"
              v-bind:key="i"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="trenutniDan.komentar.nina[i].checked"
              />

              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="trenutniDan.komentar.nina[i].name"
                spellcheck="false"
              ></textarea>
              <!-- <input class="form-control" type="text" name=""  v-model="trenutniDan.komentar.nina[i].name"> -->
              <div class="w-100 d-flex justify-content-end">
                <button
                  class="left btn-warning my-2"
                  type="button"
                  name="button"
                  @click="ukloniTask(i, 1)"
                >
                  ukloni
                </button>
              </div>
            </div>
            <button
              class="left btn btn-warning w-100 my-1"
              type="button"
              name="button"
              @click="increaseTask(1)"
            >
              Dodaj task
            </button>
          </div>

          <div class="">
            <div class="text-center">
              <label
                class="my-2 h3"
                for="exampleFormControlTextarea1"
                style="text-align:center;"
                ><b>Nataša</b></label
              >
            </div>

            <div
              class="form-check"
              v-for="(task, i) in trenutniDan.komentar?.natasa"
              v-bind:key="i"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                v-model="trenutniDan.komentar.natasa[i].checked"
              />
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="trenutniDan.komentar.natasa[i].name"
                spellcheck="false"
              ></textarea>

              <div class="w-100 d-flex justify-content-end">
                <button
                  class="left btn-warning my-2"
                  type="button"
                  name="button"
                  @click="ukloniTask(i, 2)"
                >
                  ukloni
                </button>
              </div>
            </div>
            <button
              class="left btn btn-warning w-100 my-1"
              type="button"
              name="button"
              @click="increaseTask(2)"
            >
              Dodaj task
            </button>
          </div>

          <div class="">
            <div class="text-center">
              <label
                class="my-1 h3"
                for="exampleFormControlTextarea1"
                style="text-align:center;"
                ><b>Podrška</b></label
              >
            </div>

            <div
              class="form-check"
              v-for="(task, i) in trenutniDan.komentar?.zorica"
              v-bind:key="i"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                v-model="trenutniDan.komentar.zorica[i].checked"
              />
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="trenutniDan.komentar.zorica[i].name"
                spellcheck="false"
              ></textarea>

              <div class="w-100 d-flex justify-content-end">
                <button
                  class="left btn-warning my-2"
                  type="button"
                  name="button"
                  @click="ukloniTask(i, 3)"
                >
                  ukloni
                </button>
              </div>
            </div>
            <button
              class="left btn btn-warning w-100 my-1"
              type="button"
              name="button"
              @click="increaseTask(3)"
            >
              Dodaj task
            </button>
          </div>

          <div class="">
            <div class="text-center">
              <label
                class="my-1 h3"
                for="exampleFormControlTextarea1"
                style="text-align:center;"
                ><b>Anja</b></label
              >
            </div>

            <div
              class="form-check"
              v-for="(task, i) in trenutniDan.komentar?.anja"
              v-bind:key="i"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                v-model="trenutniDan.komentar.anja[i].checked"
              />
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="trenutniDan.komentar.anja[i].name"
                spellcheck="false"
              ></textarea>

              <div class="w-100 d-flex justify-content-end">
                <button
                  class="left btn-warning my-1"
                  type="button"
                  name="button"
                  @click="ukloniTask(i, 4)"
                >
                  ukloni
                </button>
              </div>
            </div>
            <button
              class="left btn btn-warning w-100 my-2"
              type="button"
              name="button"
              @click="increaseTask(4)"
            >
              Dodaj task
            </button>
          </div>
        </div>

        <div class="validation">
          {{ validation }}
        </div>

        <div class="submitDiv">
          <button
            class="btn btn-warning bg-gradient w-100 fw-bold mt-4 py-3"
            @click="submit"
          >
            Sačuvaj Izmene
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";
import io from "socket.io-client";
export default {
  data() {
    return {
      currentDate: {},
      mesec: "",
      mesecBroj: 0,
      dani: [],
      brojDana: 0,
      prviDanUMesecu: 0,
      danUNedelji: 0,
      trenutniDan: {},
      godina: 2024,
      currentPlanner: {},
      validation: "",
      validation2: "",
      dani1: [],
      dani2: [],
      dani3: [],
      dani4: [],
      dani5: [],
      plannerNumber: 0,
      mobile: false,
      currentMonth: 0,
      //za svaku narednu godinu povecati monthAdditive za +12 a za 2025 dodati u BE jos 12 objekata.
      monthAdditive: 30,
      monthForCalendar: 0,

      trenutniDanIndex: 0,

      lockedPlanner: false,
      iamthekey: false,
      mesecX: "Decembar",
      godinaX: 2024,
      daniX: [],
    };
  },
  computed: mapGetters(["formulari", "user", "planners"]),
  methods: {
    ...mapActions([
      "getAllFormulars",
      "getProfile",
      "submitMesecPlanner",
      "getAllPlanners",
      "putPlanner",
      "lockPlanner",
    ]),

    async ucitaj() {
      await this.getAllPlanners();
      this.currentPlanner = this.planners[this.plannerNumber];
      this.reload();
      const d = new Date();
      let dtime = d.getHours();
      let dmin = d.getMinutes();
      let strMin = "";
      if (dmin < 10) {
        strMin = "0" + dmin;
      } else {
        strMin = dmin;
      }
      this.validation2 =
        "Ucitan Planer. Vreme ucitavanja: " + dtime + ":" + strMin;
    },
    async lockPlannerthis() {
      let data = {
        lock: true,
        id: this.currentPlanner._id,
      };

      await this.lockPlanner(data);
      await this.getAllPlanners();
      this.currentPlanner = this.planners[this.plannerNumber];
      this.iamthekey = true;
      this.reload();
      if (this.socket) {
        this.socket.emit("sendMessage", true);
      } else {
        console.error("WebSocket connection not initialized");
      }
    },
    async unlockPlannerthis() {
      let data = {
        lock: false,
        id: this.currentPlanner._id,
      };

      await this.lockPlanner(data);
      await this.getAllPlanners();
      this.currentPlanner = this.planners[this.plannerNumber];
      this.iamthekey = false;
      this.reload();
      if (this.socket) {
        this.socket.emit("sendMessage", false);
      } else {
        console.error("WebSocket connection not initialized");
      }
    },
    addPlanner() {
      this.daniX = [];

      let komentarX = {
        nina: [],
        natasa: [],
        zorica: [],
        anja: [],
      };

      for (var i = 0; i < 31; i++) {
        let dan = {
          mesec: this.mesecX,
          dan: i,
          komentar: komentarX,
          sastanci: [],
        };
        this.daniX.push(dan);
      }

      let data = {
        mesec: this.mesecX,
        godina: this.godinaX,
        dani: this.daniX,
      };

      this.submitMesecPlanner(data);
    },
    selectTrenutni(i) {
      this.trenutniDan = this.dani[i];
      this.trenutniDanIndex = i;
    },
    ukloniSastanak(i) {
      this.trenutniDan.sastanci.splice(i, 1);
    },
    ukloniTask(i, j) {
      if (j == 1) {
        this.trenutniDan.komentar.nina.splice(i, 1);
      }
      if (j == 2) {
        this.trenutniDan.komentar.natasa.splice(i, 1);
      }
      if (j == 3) {
        this.trenutniDan.komentar.zorica.splice(i, 1);
      }
      if (j == 4) {
        this.trenutniDan.komentar.anja.splice(i, 1);
      }
    },
    increaseSastanak() {
      let emptyStr = "";

      let sastanak = {
        name: emptyStr,
        startTime: "",
        endTime: "",
      };

      this.trenutniDan.sastanci.push(sastanak);
    },
    increaseTask(i) {
      let emptyStr = "";
      let chk = false;
      let task = {
        name: emptyStr,
        checked: chk,
      };
      if (i == 1) {
        this.trenutniDan.komentar.nina.push(task);
      }
      if (i == 2) {
        this.trenutniDan.komentar.natasa.push(task);
      }
      if (i == 3) {
        this.trenutniDan.komentar.zorica.push(task);
      }
      if (i == 4) {
        this.trenutniDan.komentar.anja.push(task);
      }
    },
    async submit() {
      await this.getAllPlanners();
      this.currentPlanner = this.planners[this.plannerNumber];
      if (this.currentPlanner.locked && this.iamthekey != true) {
        this.validation = "Planner je zakljucan, ne mozete vrsiti izmene.";
      } else {
        this.validation = "";
        let mesec = this.mesec;
        let godina = this.godina;
        let dani = [];
        for (var i = 0; i < this.dani?.length; i++) {
          if (this.dani[i].dan != 0) {
            dani.push(this.dani[i]);
          }
        }

        // SORTING START
        let testSort = [];
        for (i = 0; i < dani?.length; i++) {
          testSort.push(dani[i]);
        }

        //Svaki dan
        for (i = 0; i < testSort?.length; i++) {
          //i - ti element u sortiranom
          for (var j = 0; j < testSort[i].sastanci?.length; j++) {
            let minHour = 25;
            let minMinute = 61;
            let minIndex = 0;
            for (var k = j; k < testSort[i].sastanci?.length; k++) {
              let hour = parseInt(
                testSort[i].sastanci[k].startTime.substring(0, 2)
              );

              let minute = parseInt(
                testSort[i].sastanci[k].startTime.substring(3, 5)
              );

              //if
              if (hour < minHour) {
                minHour = hour;
                minMinute = minute;
                minIndex = k;
              } else {
                if (hour == minHour) {
                  if (minute < minMinute) {
                    minHour = hour;
                    minMinute = minute;
                    minIndex = k;
                  }
                }
              }
              //if end
            }

            //swap elements
            let temp = testSort[i].sastanci[j];
            // console.log("temp ");
            // console.log(temp);
            testSort[i].sastanci[j] = testSort[i].sastanci[minIndex];
            // console.log("testSort[i].sastanci[j]");
            // console.log(testSort[i].sastanci[j]);
            testSort[i].sastanci[minIndex] = temp;
            // console.log("testSort[i].sastanci[minIndex]");
            // console.log(testSort[i].sastanci[minIndex]);
            //swap end
          }
        }

        for (i = 0; i < testSort?.length; i++) {
          // console.log("DAN "+i+".");
          for (j = 0; j < testSort[i].sastanci?.length; j++) {
            // console.log(testSort[i].sastanci[j]);
          }
        }

        // SORTING END

        let data = {
          mesec,
          godina,
          dani,
          id: this.currentPlanner._id,
        };
        await this.putPlanner(data);
        this.validation = `Uspesno sacuvan mesec ${mesec} ${godina}.`;
        await this.getAllPlanners();
        //this.reload();
      }
    },
    reload() {
      console.log("REALOAD: ", this.brojDana, this.mesecBroj, this.godina);
      this.dani = [];

      if (this.currentPlanner.locked) {
        this.lockedPlanner = true;
      } else {
        this.lockedPlanner = false;
      }
      console.log(
        "this.brojDana+this.prviDanUMesecu:",
        this.brojDana + this.prviDanUMesecu
      );
      for (var i = 0; i < this.brojDana + this.prviDanUMesecu; i++) {
        let dan;
        if (i < this.prviDanUMesecu) {
          dan = {
            mesec: "",
            dan: 0,
            komentar: {},
            sastanci: "",
          };
        } else {
          dan = {
            mesec: this.mesec,
            dan: i + 1 - this.prviDanUMesecu,
            // komentar:this.currentPlanner.dani[i-this.prviDanUMesecu].komentar,

            komentar: this.currentPlanner.dani[i - this.prviDanUMesecu]
              ?.komentar,
            sastanci: this.currentPlanner.dani[i - this.prviDanUMesecu]
              ?.sastanci,
          };
        }

        this.dani.push(dan);
      }
      console.log("DANI:", this.dani);
      this.dani1 = [];
      this.dani2 = [];
      this.dani3 = [];
      this.dani4 = [];
      this.dani5 = [];
      for (i = 0; i < 7; i++) {
        this.dani1[i] = this.dani[i];
      }
      for (i = 7; i < 14; i++) {
        this.dani2[i - 7] = this.dani[i];
      }
      for (i = 14; i < 21; i++) {
        this.dani3[i - 14] = this.dani[i];
      }
      for (i = 21; i < 28; i++) {
        this.dani4[i - 21] = this.dani[i];
      }
      for (i = 28; i < this.dani?.length; i++) {
        this.dani5[i - 28] = this.dani[i];
      }

      this.trenutniDan = this.dani[this.trenutniDanIndex];
      console.log("TRENUTNI DAN:", this.trenutniDan);
    },
    lastYear() {
      if (this.godina > 2022) {
        this.godina--;
        this.plannerNumber -= 12;
        this.currentPlanner = this.planners[this.plannerNumber];
        this.reload();
      }
    },
    nextYear() {
      if (this.godina < 2024) {
        this.godina++;
        this.plannerNumber += 12;
        this.currentPlanner = this.planners[this.plannerNumber];
        this.reload();
      }
    },
    nextMonth() {
      if (!(this.mesecBroj == 11 && this.godina == 2024)) {
        if (this.mesecBroj < 11) {
          this.mesecBroj += 1;
        } else {
          this.mesecBroj = 0;
          this.godina++;
        }

        this.prviDanUMesecu = (this.prviDanUMesecu + this.brojDana) % 7;

        if (this.mesecBroj == 0) {
          this.mesec = "Januar";
          this.brojDana = 31;
        }
        if (this.mesecBroj == 1) {
          this.mesec = "Februar";
          this.brojDana = this.godina == 2024 || this.godina == 2028 ? 29 : 28;
        }
        if (this.mesecBroj == 2) {
          this.mesec = "Mart";
          this.brojDana = 31;
        }
        if (this.mesecBroj == 3) {
          this.mesec = "April";
          this.brojDana = 30;
        }
        if (this.mesecBroj == 4) {
          this.mesec = "Maj";
          this.brojDana = 31;
        }
        if (this.mesecBroj == 5) {
          this.mesec = "Jun";
          this.brojDana = 30;
        }
        if (this.mesecBroj == 6) {
          this.mesec = "Jul";
          this.brojDana = 31;
        }
        if (this.mesecBroj == 7) {
          this.mesec = "Avgust";
          this.brojDana = 31;
        }
        if (this.mesecBroj == 8) {
          this.mesec = "Septembar";
          this.brojDana = 30;
        }
        if (this.mesecBroj == 9) {
          this.mesec = "Oktobar";
          this.brojDana = 31;
        }
        if (this.mesecBroj == 10) {
          this.mesec = "Novembar";
          this.brojDana = 30;
        }
        if (this.mesecBroj == 11) {
          this.mesec = "Decembar";
          this.brojDana = 31;
        }
        this.plannerNumber += 1;
        this.currentPlanner = this.planners[this.plannerNumber];
        this.trenutniDanIndex = 9;

        // for ( var i = 0; i < this.planners.length; i++) {
        //   if(this.planners[i].mesec == this.mesec){
        //     this.currentPlanner=this.planners[i];
        //   }
        // }

        this.reload();
      }
    },
    lastMonth() {
      if (this.mesecBroj == 0 && this.godina == 2022) return;
      if (this.mesecBroj > 0) {
        this.mesecBroj -= 1;
      } else {
        this.mesecBroj = 11;
        this.godina--;
      }

      this.plannerNumber -= 1;
      if (this.plannerNumber < 3) {
        this.plannerNumber = 3;
        this.mesecBroj = 0;
      }

      if (this.mesecBroj == 0) {
        this.mesec = "Januar";
        this.brojDana = 31;
      }
      if (this.mesecBroj == 1) {
        this.mesec = "Februar";
        this.brojDana = this.godina == 2024 || this.godina == 2028 ? 29 : 28;
      }
      if (this.mesecBroj == 2) {
        this.mesec = "Mart";
        this.brojDana = 31;
      }
      if (this.mesecBroj == 3) {
        this.mesec = "April";
        this.brojDana = 30;
      }
      if (this.mesecBroj == 4) {
        this.mesec = "Maj";
        this.brojDana = 31;
      }
      if (this.mesecBroj == 5) {
        this.mesec = "Jun";
        this.brojDana = 30;
      }
      if (this.mesecBroj == 6) {
        this.mesec = "Jul";
        this.brojDana = 31;
      }
      if (this.mesecBroj == 7) {
        this.mesec = "Avgust";
        this.brojDana = 31;
      }
      if (this.mesecBroj == 8) {
        this.mesec = "Septembar";
        this.brojDana = 30;
      }
      if (this.mesecBroj == 9) {
        this.mesec = "Oktobar";
        this.brojDana = 31;
      }
      if (this.mesecBroj == 10) {
        this.mesec = "Novembar";
        this.brojDana = 30;
      }
      if (this.mesecBroj == 11) {
        this.mesec = "Decembar";
        this.brojDana = 31;
      }

      this.currentPlanner = this.planners[this.plannerNumber];

      // for (var  i = 0; i < this.planners.length; i++) {
      //   if(this.planners[i].mesec == this.mesec){
      //     this.currentPlanner=this.planners[i];
      //   }
      // }

      this.prviDanUMesecu = (this.prviDanUMesecu - this.brojDana) % 7;
      if (this.prviDanUMesecu < 0) {
        this.prviDanUMesecu = this.prviDanUMesecu + 7;
      }
      this.trenutniDanIndex = 9;
      this.reload();
    },
  },

  async created() {
    this.socket = io("https://keysolutions.herokuapp.com");

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    await this.getProfile();
    await this.getAllPlanners();
    this.currentDate = new Date();
    this.currentMonth = this.currentDate.getMonth();
    this.monthForCalendar = this.currentMonth + this.monthAdditive;

    this.currentPlanner = this.planners[this.monthForCalendar];
    this.plannerNumber = this.monthForCalendar;

    if (this.currentPlanner.locked) {
      this.lockedPlanner = true;
    } else {
      this.lockedPlanner = false;
    }

    this.originalPlanner = this.planners[this.monthForCalendar];

    if (this.currentDate.getMonth() == 0) {
      this.mesec = "Januar";
      this.brojDana = 31;
    }
    if (this.currentDate.getMonth() == 1) {
      this.mesec = "Februar";
      this.brojDana = this.godina == 2024 || this.godina == 2028 ? 29 : 28;
    }
    if (this.currentDate.getMonth() == 2) {
      this.mesec = "Mart";
      this.brojDana = 31;
    }
    if (this.currentDate.getMonth() == 3) {
      this.mesec = "April";
      this.brojDana = 30;
    }
    if (this.currentDate.getMonth() == 4) {
      this.mesec = "Maj";
      this.brojDana = 31;
    }
    if (this.currentDate.getMonth() == 5) {
      this.mesec = "Jun";
      this.brojDana = 30;
    }
    if (this.currentDate.getMonth() == 6) {
      this.mesec = "Jul";
      this.brojDana = 31;
    }
    if (this.currentDate.getMonth() == 7) {
      this.mesec = "Avgust";
      this.brojDana = 31;
    }
    if (this.currentDate.getMonth() == 8) {
      this.mesec = "Septembar";
      this.brojDana = 30;
    }
    if (this.currentDate.getMonth() == 9) {
      this.mesec = "Oktobar";
      this.brojDana = 31;
    }
    if (this.currentDate.getMonth() == 10) {
      this.mesec = "Novembar";
      this.brojDana = 30;
    }
    if (this.currentDate.getMonth() == 11) {
      this.mesec = "Decembar";
      this.brojDana = 31;
    }
    this.mesecBroj = this.currentDate.getMonth();

    this.danUNedelji = this.currentDate.getDay();
    this.prviDanUMesecu = (this.danUNedelji - this.currentDate.getDate()) % 7;
    if (this.prviDanUMesecu < 0) {
      this.prviDanUMesecu = this.prviDanUMesecu + 7;
    }

    this.reload();

    this.trenutniDan = this.dani[
      this.prviDanUMesecu - 1 + this.currentDate.getDate()
    ];
    this.trenutniDanIndex =
      this.prviDanUMesecu - 1 + this.currentDate.getDate();
  },
};
</script>

<style lang="css" scoped>
.kalendar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . . . ."
    ". . . . . . ."
    ". . . . . . ."
    ". . . . . . ."
    ". . . . . . .";

  border-style: solid;
  border-color: black;
  border-width: medium;
  padding: 10px;
}
.kalendar1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . . . . . .";

  border-style: solid;
  border-color: black;
  border-width: medium;
  border-bottom: 0px;
}
.kalendar2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . . . . . .";

  border-style: solid;
  border-color: black;
  border-width: medium;
}
.headerKalendar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "leva leva mesec mesec mesec desna desna"
    ". . . . . . .";

  border-style: solid;
  border-color: black;
  border-width: medium;
  padding: 10px;

  border-bottom: 0px;
}
.headerGodina {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "leva leva mesec mesec mesec desna desna";

  border-style: solid;
  border-color: black;
  border-width: medium;
  padding: 10px;

  border-bottom: 0px;
}

/* "leva leva mesec mesec mesec desna desna"
". . . . . . ."
0.2fr 0.2fr */
.leva {
  grid-area: leva;
}
.desna {
  grid-area: desna;
}
.mesec {
  text-align: center;
  grid-area: mesec;
}
.dan {
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 10px;
  height: 250px;
  overflow: auto;
}
.danMobile {
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-bottom: 0px;
  border-top: 0px;
}
.radniDan {
  background-color: #8888ff;
  color: white;
  padding: 5px;
  border-style: solid;
  border-color: black;
  border-width: thin;
}
.vikend {
  background-color: #88ff88;
  color: white;
  padding: 5px;
  border-style: solid;
  border-color: black;
  border-width: thin;
}
.trenutniDan {
  padding: 5px;
  border-style: solid;
  border-color: black;
  border-width: thin;

  margin: auto;
}

.right {
  background-color: #ffaaaa;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
  float: right;
}
.left {
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
}

.submitDiv {
  width: 100%;
}
.btnSub {
  width: 100%;
  background-color: #ffaaaa;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
}
.btnCommands {
  width: 100%;
  background-color: #aaaaff;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;

  height: 40px;
}
.taskovi {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . . .";
}
.taskoviMobile {
}
.buttonsDiv {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . .";
}
</style>
